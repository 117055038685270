import React from "react";
import { Provider } from "react-redux";
import store from "../coms/data/store";

import Seo from "../coms/global/Seo";
import MainFrame from "../coms/global/MainFrame";
import Layout from "../coms/global/Layout";
import ExploreView from "../coms/explorer/ExploreView";

const ExplorePage = () => {
  return (
    <>
      <Seo
        title="Discover interesting projects on Schoode"
        pathname="/explore"
      />
      <Provider store={store}>
        <MainFrame>
          <Layout>
            <ExploreView />
          </Layout>
        </MainFrame>
      </Provider>
    </>
  );
};

export default ExplorePage;

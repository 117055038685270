import React from "react";
import { makeStyles } from "@mui/styles";

import Header from "./Header";
import Footer from "./Footer";
import AlertBar from "./AlertBar";

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Header />
      </div>

      <div className={classes.main}> {children}</div>

      <AlertBar />
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "flex-start",
      backgroundColor: theme.palette.grey[100],
      width: "100%",
      minHeight: "100vh",
    },
    header: {
      position: "relative",
      display: "block",
      width: "100%",
    },
    main: {
      position: "relative",
      display: "block",
      width: "100%",
      flexGrow: 1,
    },
    footer: {
      position: "relative",
      display: "block",
      width: "100%",
    },
  };
});

export default Layout;

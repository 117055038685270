import React from "react";

import { ButtonBase, CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LoadMore = ({ loading, onClick }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "block",
        borderTop: "1px solid #eee",
        width: "100%",
        margin: 0,
        padding: 0,
      }}
    >
      <ButtonBase
        sx={{
          width: "100%",
          minHeight: 35,
          textAlign: "center",
        }}
        variant="contained"
        onClick={onClick}
      >
        {loading ? (
          <CircularProgress size={25} color="primary" />
        ) : (
          <ExpandMoreIcon style={{ fontSize: 25, color: "#555" }} />
        )}
      </ButtonBase>
    </div>
  );
};

export default LoadMore;

import React from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Typography, Avatar, Stack } from "@mui/material";

import { DIR_CDN_IMG } from "../data/adresses";
import { gotoPage } from "../tools/browserHelpers";

const FeedsCard = ({ itmData }) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <Stack spacing={1}>
        <div
          className={classes.action}
          onClick={() => {
            gotoPage("/play?id=" + itmData.id);
          }}
        >
          <Paper className={classes.paper}>
            <img
              crossOrigin="anonymous"
              alt=""
              className={classes.media}
              src={DIR_CDN_IMG + itmData.img}
              title={itmData.title}
            />
          </Paper>
        </div>
        <Typography variant="subtitle2" className={classes.title}>
          {itmData.title}
        </Typography>
        <div
          className={classes.meta}
          onClick={() => {
            gotoPage("/profile?id=" + itmData.uid);
          }}
        >
          <Avatar
            sx={{ margin: "2px 3px" }}
            src={itmData.ava_img ? DIR_CDN_IMG + itmData.ava_img : null}
          >
            {itmData.uname[0].toUpperCase()}
          </Avatar>
          <Typography variant="subtitle2" noWrap>
            {itmData.uname}
          </Typography>
        </div>
        <Typography variant="body2" className={classes.time}>
        </Typography>
      </Stack>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    display: "block",
    position: "relative",
    padding: theme.spacing(1),
    width: "250px",
    [theme.breakpoints.down("sm")]: {
      width: "280px",
    },
  },
  action: {
    display: "block",
    position: "relative",
    width: "100%",
    cursor: "pointer",
  },
  paper: {
    display: "block",
    position: "relative",
    width: "100%",
    padding: "8px",
  },
  media: {
    position: "relaive",
    display: "block",
    backgroundColor: "#fff",
    objectFit: "cover",
    width: "100%",
    height: "130px",
  },
  title: {
    position: "relaive",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  meta: {
    display: "flex",
    flexDirection: "raw",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
  },
  time: {
    width: "100%",
    padding: "8px",
    color: theme.palette.text.secondary,
  },
}));

export default FeedsCard;

import React, { useEffect, useState } from "react";

import {
  Container,
  Paper,
  Box,
  Stack,
  TextField,
  IconButton,
  Typography,
  LinearProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

import LoadMore from "../tools/LoadMore";
import FeedsGallery from "./FeedsGallery";

import ProjectAPI from "../api/projectApi";
import { useSelector } from "react-redux";

const ExploreView = () => {
  const [feedsList, setFeedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterTxt, setFilterTxt] = useState("");
  const text = useSelector((s) => s.settings.texts.explorer);

  const calcDateMsg = (ts) => {
    try {
      var date = new Date(ts);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    } catch (error) {}
    return "";
  };

  const hChangeFilter = (ev) => {
    setFilterTxt(ev.target.value);
  };

  const loadFeeds = async (ps) => {
    if (loading || ps < 0) return;

    setLoading(true);
    ProjectAPI.getGlobalFeeds(
      filterTxt.trim(),
      ps,
      10,
      (m) => {
        setLoading(false);
        if (Array.isArray(m)) {
          if (m.length > 0) {
            var ss = [];
            if (ps > 0) {
              ss = [...feedsList];
            }
            m.forEach((itm) => {
              ss.push({ ...itm, time: calcDateMsg(itm.created_at) });
            });
            setFeedList(ss);
          }
        }
      },
      (x) => {
        setLoading(false);
      }
    );
  };

  const hLoadMore = () => {
    if (!loading && feedsList.length < 150) {
      loadFeeds(feedsList.length);
    }
  };

  const hSearch = () => {
    if (filterTxt.length > 0) {
      setFeedList([]);
      loadFeeds(0);
    }
  };

  useEffect(() => {
    setFeedList([]);
    loadFeeds(0);
  }, []);

  const showList = () => {
    if (Array.isArray(feedsList)) {
      if (feedsList.length > 0) {
        return (
          <>
            <FeedsGallery feedsData={feedsList} />

            <LoadMore
              loading={loading}
              onClick={() => {
                hLoadMore();
              }}
            />
          </>
        );
      }
    }

    if (!loading) {
      return (
        <Box
          sx={{
            width: "100%",
            padding: "50px 0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TravelExploreIcon sx={{ fontSize: 50, color: "#895" }} />
          <Typography>{text.nofeeds}</Typography>
        </Box>
      );
    }

    return <></>;
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "10px 0px" }}>
      <Stack spacing={2}>
        <Paper
          sx={{
            position: "relative",
            width: "100%",
            padding: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <TextField
            size="small"
            placeholder="..."
            value={filterTxt}
            onChange={hChangeFilter}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                hSearch();
              }
            }}
          />
          <IconButton
            sx={{ p: "10px", m: "5px" }}
            aria-label=""
            onClick={hSearch}
          >
            <SearchIcon />
          </IconButton>

          <LinearProgress
            sx={{
              display: loading ? "block" : "none",
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
            }}
            color="secondary"
          />
        </Paper>

        <Box
          sx={{
            backgroundColor: "#fafafa",
            position: "relative",
            display: "block",
            minHeight: "500px",
            padding: "10px 0px",
          }}
        >
          {showList()}
        </Box>
      </Stack>
    </Container>
  );
};

export default ExploreView;

import React from "react";
import { makeStyles } from "@mui/styles";

import FeedsCard from "./FeedCard";

const FeedsGallery = ({ feedsData }) => {
  const classes = useStyles();

  const fillFeedsList = () => {
    if (Array.isArray(feedsData)) {
      return feedsData.map((itm, i) => {
        return <FeedsCard key={i} itmData={itm} />;
      });
    }

    return <></>;
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>{fillFeedsList()}</div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "10px 0px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-around",
    width: "100%",
  },
}));

export default FeedsGallery;

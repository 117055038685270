import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function Seo({ title, description, pathname }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            image
            keywords
          }
        }
      }
    `
  );

  const metaTitle = site.siteMetadata.title + (title ? " | " + title : "");
  const metaDescription = description || site.siteMetadata.description;
  var imageUrl = `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`;
  var imageW = 700;
  var imageH = 350;

  const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={site.siteMetadata.keywords} />

      {canonical && <link rel="canonical" href={canonical} />}

      {canonical && <meta property="og:url" content={canonical} />}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={imageW} />
      <meta property="og:image:height" content={imageH} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@dev_Kood" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
}

Seo.defaultProps = {
  title: ``,
  description: ``,
  pathname: ``,
};

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
};

export default Seo;

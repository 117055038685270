import SecureAPI from "./SecureAPI";
import { API_SBP } from "../data/adresses";

class ProjectAPI extends SecureAPI {
  constructor() {
    super(API_SBP);
  }

  async getUserFeeds(user, uid, offset, count, onOk, onErr = null) {
    await this.Get(
      user,
      "/list?uid=" + uid + "&of=" + offset + "&cnt=" + count,
      onOk,
      onErr
    );
  }

  async getGlobalFeeds(text, offset, count, onOk, onErr = null) {
    var txt = "*";
    if (text) {
      if (text.length !== undefined) {
        if (text.length > 0) {
          txt = text;
        }
      }
    }

    await this.Get(
      null,
      "/feeds?of=" + offset + "&cnt=" + count + "&txt=" + txt,
      onOk,
      onErr
    );
  }

  async getSamples(onOk, onErr = null) {
    await this.Get(null, "/samples", onOk, onErr);
  }

  async saveProject(user, payload, onOk, onErr = null) {
    await this.PostFiles(user, "/save", payload, onOk, onErr);
  }

  async getProject(user, aim, pid, onOk, onErr = null) {
    await this.Get(user, "/read?pid=" + pid + "&aim=" + aim, onOk, onErr);
  }

  async deleteProject(user, pid, onOk, onErr = null) {
    await this.Get(user, "/del?pid=" + pid, onOk, onErr);
  }
}

export default new ProjectAPI();
